import * as React from "react";
import {useEffect, useState} from "react";
import {useDataProvider, useNotify, useTheme} from "react-admin";
import Resources from "../../Resources";
import {onError} from "../../common/utils";
import Grid from "@mui/material/Grid";
import {Typography} from "@mui/material";
import {mangoFusionPalette} from "@mui/x-charts/colorPalettes";
import {BarChart} from "@mui/x-charts";

export const LegalEntitiesByAum = (props) => {
    const [data, setData] = useState([])
    const [series, setSeries] = useState([]);
    const [xAxis, setXAxis] = useState([])
    const [totalRow, setTotalRow] = useState()
    //todo use loading
    const [loading, setLoading] = useState(false)
    const dataProvider = useDataProvider();
    const [theme] = useTheme();
    const notify = useNotify();
    const {currency, operatedDateFrom, operatedDateTo, dict, label} = props
    // const currency = useWatch({name: "currency"})
    // const dateFrom = useWatch({name: "createdDateFrom"})
    // const dateTo = useWatch({name: "createdDateTo"})

    const toISODate = (d) => {
        return d?.toISOString ? d.toISOString().split("T")[0] : d
    }

    const loadData = () => {
        setLoading(true)
        dataProvider.doAction(Resources.DASHBOARD.LEGAL_ENTITIES_SUMMARY_BY_AUM.name, {
            method: 'GET',
            queryStr: `currency=${currency}&operatedDateFrom=${toISODate(operatedDateFrom)}&operatedDateTo=${toISODate(operatedDateTo)}`
        })
            .then(({data}) => {
                // console.log("data", data);
                const xlabels = []
                const arr = []
                const withoutTotal = []
                data.data.forEach(e => {
                    if (e.id === -1) {
                        // total row
                        setTotalRow(e)
                    } else {
                        xlabels[xlabels.length] = dict[e.id]?.name
                        arr[arr.length] = e.count
                        withoutTotal[withoutTotal.length] = e
                    }
                })

                setXAxis([{
                    label: '',
                    scaleType: 'band',
                    data: xlabels,
                }])

                // console.log("series", arr)
                setSeries([{
                    data: arr, label: 'Count', stack: 'total'
                }])

                setData(withoutTotal)
                setLoading(false)
            })
            .catch((err) => {
                setLoading(false)
                onError(err, notify)
            });
    }

    useEffect(() => {
        loadData()
    }, [currency, operatedDateFrom, operatedDateTo, dict]);

    const legendPlacement = {
        slotProps: {
            legend: {
                position: {
                    vertical: 'middle',
                    horizontal: 'right',
                },
                direction: 'column',
                itemGap: 2,
            },
        },
        margin: {
            top: 20,
            right: 100,
        },
    }

    return (
        <Grid container columnSpacing={1} rowSpacing={1} className={`dashboard-grid dashboard-grid-${theme}`}>
            <Grid item xs={6}>
                <div className="header">
                    <Typography variant="h12" sx={{width: "100%"}}>
                        {label}
                    </Typography>
                </div>
                <table>
                    <thead>
                    <tr>
                        <th>Assets under Management</th>
                        <th>Number of Legal Entities</th>
                    </tr>
                    </thead>
                    <tbody>
                    {data?.map((e, idx) => (
                            <tr key={idx}>
                                <td>{dict[e.id]?.name}</td>
                                <td className="fmt-number">{e.count}</td>
                            </tr>
                        )
                    )}
                    </tbody>
                    <tfoot>
                    {totalRow && <tr>
                        <td>TOTAL</td>
                        <td className="fmt-number">{totalRow.count}</td>
                    </tr>}
                    </tfoot>
                </table>
            </Grid>
            <Grid item xs={6}>
                <BarChart
                    series={series}
                    width={700}
                    // height={300}
                    colors={mangoFusionPalette}
                    xAxis={xAxis}
                    {...legendPlacement}
                />
            </Grid>
        </Grid>
    )
}