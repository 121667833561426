import PropTypes from "prop-types";
import React from "react";
import Resources from "../../Resources";
import PaginationBar from "../controls/PaginationBar";
import {BooleanField, Datagrid, FunctionField, List, TextField} from "react-admin";
import EnumDictField from "../controls/EnumDictField";
import {NDAStatus} from "../dicts/NDAStatusEnum";
import {LoanApplicationCreditorStatus} from "../dicts/LoanApplicationCreditorRelStatusEnum";
import Link from "@mui/material/Link";
import {formatAmount} from "../../common/utils";
import CustomListEmpty from "../controls/CustomListEmpty";

const AvailableOpportunities = (props) => {
    const {creditorApplication} = props;

    return (
        <List disableSyncWithLocation
              resource={Resources.CREDITOR_APPLICATIONS.name + "/" + creditorApplication.id
                  + "/" + Resources.CREDITOR_APPLICATIONS.subrequests.CREDITOR_RELS.name}
              sort={{field: "id", order: "DESC"}}
              pagination={<PaginationBar/>} perPage={50} exporter={false} empty={<CustomListEmpty noBorder/>}
        >
            <Datagrid rowClick={false} bulkActionButtons={false}>
                <TextField source="id"/>
                <FunctionField label="Loan Application" render={record => {
                    let la = record.loanApplication;
                    return la
                        ? <Link
                            href={`/#/loanApplications/${record.loanApplicationId}/show/4`}>
                            {formatAmount(la.currency, la.loanAmount)}
                        </Link>
                        : "-"
                }
                }/>
                <EnumDictField enum={NDAStatus} source="ndaStatusCode" label="NDA"/>
                <BooleanField source="loiIsAccepted" label="Access to Full DD"/>
                <BooleanField source="isCreditorVerified" label="Is Debt Investor verified?"/>
                <FunctionField label="Confirmed by Borrower"
                               render={record => {
                                   if (record.isConfirmedByBorrower === undefined)
                                       return "Not required";
                                   return <BooleanField source="isConfirmedByBorrower" label="Confirmed by Borrower"/>
                               }
                               }/>
                <FunctionField label="NDA from Debt Investor" render={record => {
                    return record.ndaFromCreditorDoc
                        ? <Link
                            href={`/api/v1/loanApplications/${record.loanApplicationId}/creditors/${record.creditorId}/nda/${record.ndaFromCreditorDocId}`}>
                            {record.ndaFromCreditorDoc.filename}
                        </Link>
                        : "-"
                }
                }/>
                <FunctionField label="NDA from Monivolt" render={record => {
                    return record.ndaFromMonivoltDoc
                        ? <Link
                            href={`/api/v1/loanApplications/${record.loanApplicationId}/creditors/${record.creditorId}/nda/${record.ndaFromMonivoltDocId}`}>
                            {record.ndaFromMonivoltDoc.filename}
                        </Link>
                        : "-"
                }
                }/>
                <EnumDictField enum={LoanApplicationCreditorStatus} source="statusCode" />
            </Datagrid>
        </List>
    )
}

export default AvailableOpportunities;

AvailableOpportunities.propTypes = {
    creditorApplication: PropTypes.object.isRequired,
}