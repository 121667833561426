import PropTypes from "prop-types";
import React from "react";
import Resources from "../../Resources";
import {Datagrid, DateField, FunctionField, List, ShowButton, TextField, TextInput} from "react-admin";
import PaginationBar from "../controls/PaginationBar";
import Link from "@mui/material/Link";
import EnumDictField from "../controls/EnumDictField";
import DictField from "../controls/DictField";
import {LoanApplicationStatus} from "../dicts/LoanApplicationEnum";
import FormattedAmountField from "../controls/FormattedAmountField";
import EnumDictInput from "../controls/EnumDictInput";

const RelevantLoanApplications = (props) => {
    const {creditorApplication} = props;
    const listFilters = [
        <EnumDictInput enum={LoanApplicationStatus} label="Application Status" source="statusCode"
                       alwaysOn resettable sx={{width: "210px"}}/>,
        <TextInput source="fullCompanyName" label="Borrower Company Name" resettable alwaysOn />,
    ];
    return (
        <List disableSyncWithLocation
              resource={Resources.CREDITOR_APPLICATIONS.name + "/" + creditorApplication.id
                  + "/" + Resources.CREDITOR_APPLICATIONS.subrequests.RELEVANT_LOAN_APPLICATIONS.name}
              pagination={<PaginationBar/>} perPage={50} exporter={false}
              sort={{field: "id", order: "DESC"}}
              filters={listFilters}
        >
            <Datagrid rowClick={false} bulkActionButtons={false}>
                <TextField source="id"/>
                <FunctionField label="Borrower" render={record => {
                    return record.borrowerId
                        ? <Link
                            href={`/#/legalEntities/${record.borrowerId}/show`}>
                            {record.borrowerCompanyFullName
                                ? record.borrowerCompanyFullName : "Borrower ID: " + record.borrowerId}
                        </Link>
                        : "-"
                }
                }/>
                <DateField source="targetedClosure"/>
                <DictField source="loanTypeCode" label="Loan Type" reference={Resources.DICT_LOAN_TYPES.name}/>
                <EnumDictField source="statusCode" enum={LoanApplicationStatus} />
                <FormattedAmountField
                    label="Loan Amount"
                    source="loanAmount"
                    currencyAttr="currency"
                    options={{maximumFractionDigits: 0}}
                />
                <TextField source="loanTerm"/>
                <TextField source="initiatorLogin"/>
                {/*<BooleanField source="isBorrowerConfirmationRequired"
                          label="Needs Borrower Confirm?"/>*/}
                <ShowButton data-testid="loan-app-details-link" resource={Resources.LOAN_APPLICATIONS.name} />
            </Datagrid>
        </List>
    )
}

export default RelevantLoanApplications;

RelevantLoanApplications.propTypes = {
    creditorApplication: PropTypes.object.isRequired,
}