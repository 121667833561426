import {
    Create,
    CreateButton,
    Datagrid,
    DeleteWithConfirmButton,
    Edit,
    EditButton, FunctionField,
    List,
    NumberField,
    NumberInput,
    SaveButton,
    SimpleForm,
    TextField,
    TextInput,
    Toolbar,
    TopToolbar
} from "react-admin";
import {CheckPermission} from "../security/CheckPermission";
import {AdminPortalRoles} from "./Security";
import * as React from "react";
import {FormattedNumberInput} from "../controls/FormattedNumberInput";
import FormattedAmountField from "../controls/FormattedAmountField";
import {formatAmount} from "../../common/utils";

export const AumDictList = (props) => {
    return (
        <List {...props} actions={<ListActions/>} sort={{field: "sortOrder", order: "ASC"}} perPage={50}
              filter={{includeDeleted: true}}>
            <Datagrid rowClick={false} bulkActionButtons={false}>
                <NumberField source="id"/>
                <TextField source="name"/>
{/*                <FunctionField
                    label="Amount From (including)"
                    render={(record) => {
                        return formatAmount(
                            null,
                            record.amountFromIncl,
                            {maximumFractionDigits: 0, skipCurrencyLabel: true}
                        );
                    }}
                />*/}
                <NumberField label="Amount From (including)" source="amountFromIncl"/>
                <NumberField label="Amount To (excluding)" source="amountToExcl"/>
                <NumberField source="sortOrder"/>
                <CheckPermission role={AdminPortalRoles.BACKOFFICE_ADMIN}>
                    <EditButton/>
                </CheckPermission>
            </Datagrid>
        </List>
    )
}

export const ListActions = () => (
    <TopToolbar>
        <CheckPermission role={AdminPortalRoles.BACKOFFICE_ADMIN}>
            <CreateButton/>
        </CheckPermission>
    </TopToolbar>
);

export const EditToolbar = props => {
    return <Toolbar {...props}>
        <CheckPermission role={AdminPortalRoles.BACKOFFICE_ADMIN}>
            <SaveButton/>
            <DeleteWithConfirmButton
                confirmContent="You will not be able to recover this record. Are you sure?"
                translateOptions={{name: "dictionary item"}}
            />
        </CheckPermission>
    </Toolbar>
};

export const AumDictEdit = (props) => {
    return <Edit {...props}>
        <SimpleForm toolbar={<EditToolbar/>}>
            {/*<TextInput source="code" name="code" required={true}/>*/}
            <TextInput source="name" name="name" required/>
            <FormattedNumberInput label="Amount From (including)" source="amountFromIncl" required/>
            <FormattedNumberInput label="Amount To (excluding)" source="amountToExcl" required/>
            <NumberInput source="sortOrder" name="sortOrder"/>
        </SimpleForm>
    </Edit>
}

export const AumDictCreate = (props) => (
    <Create {...props}>
        <SimpleForm>
            <TextInput source="name" name="name" required/>
            <NumberInput label="Amount From (including)" source="amountFromIncl" required/>
            <NumberInput label="Amount To (excluding)" source="amountToExcl" required/>
            <NumberInput source="sortOrder" name="sortOrder"/>
        </SimpleForm>
    </Create>
);