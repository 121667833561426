import {CheckPermission} from "../security/CheckPermission";
import {AdminPortalRoles} from "../dicts/Security";
import {BulkDeleteWithConfirmButton, usePermissions} from "react-admin";
import * as React from "react";
import PropTypes from "prop-types";
import {haveRole} from "../../common/utils";

const AuthenticatedBulkDeleteAction = (props) => {
    const {role} = props;

    return (
        <>
            <CheckPermission role={role || AdminPortalRoles.BACKOFFICE_APPROVER}>
                <BulkDeleteWithConfirmButton
                    confirmContent="You will not be able to recover this records. Are you sure?"/>
            </CheckPermission>
        </>
    );
}

export default AuthenticatedBulkDeleteAction;

AuthenticatedBulkDeleteAction.propTypes = {
    role: PropTypes.string,
}

export const AuthBulkDeleteActionOrFalse = (props) => {
    const { permissions } = usePermissions();
    const {role} = props;

    return (
        haveRole(permissions, role || AdminPortalRoles.BACKOFFICE_APPROVER)
        && <BulkDeleteWithConfirmButton confirmContent="You will not be able to recover this records. Are you sure?"/>
    )
}