import * as React from "react";
import {useEffect, useState} from "react";
import {useDataProvider, useNotify, useTheme} from "react-admin";
import Resources from "../../Resources";
import {convertArrayToObject, formatAmount, onError} from "../../common/utils";
import Grid from "@mui/material/Grid";
import {Typography} from "@mui/material";
import {PieChart} from "@mui/x-charts/PieChart";
import {mangoFusionPalette} from "@mui/x-charts/colorPalettes";

export const LoanApplicationsByLoanType = (props) => {
    const [data, setData] = useState([])
    const [loanTypes, setLoanTypes] = useState(null)
    const [graphData, setGraphData] = useState([])
    const [totalRow, setTotalRow] = useState()
    //todo use loading
    const [loading, setLoading] = useState(false)
    const dataProvider = useDataProvider();
    const [theme] = useTheme();
    const notify = useNotify();
    const {currency, createdDateFrom, createdDateTo} = props
    // const currency = useWatch({name: "currency"})
    // const dateFrom = useWatch({name: "createdDateFrom"})
    // const dateTo = useWatch({name: "createdDateTo"})

    const toISODate = (d) => {
        return d?.toISOString ? d.toISOString().split("T")[0] : d
    }

    const loadData = () => {
        setLoading(true)
        dataProvider.doAction(Resources.DASHBOARD.LOAN_APPLICATIONS_SUMMARY_BY_LOAN_TYPE.name, {
            method: 'GET',
            queryStr: `currency=${currency}&createdDateFrom=${toISODate(createdDateFrom)}&createdDateTo=${toISODate(createdDateTo)}`
        })
            .then(({data}) => {
                // console.log("data", data);
                let withoutTotal = data.data.filter(item => item.id !== 'TOTAL');
                setData(withoutTotal)
                let filter = data.data.filter(item => item.id === 'TOTAL');
                setTotalRow(filter?.length ? filter[0] : undefined);
                setGraphData(withoutTotal.map(
                    ({id, totalAmount, count, avgDealSize}) =>
                        ({id, value: count, label: loanTypes ? loanTypes[id]?.name : id}))
                )
                setLoading(false)
            })
            .catch((err) => {
                setLoading(false)
                onError(err, notify)
            });
    }

    const loadLoanTypes = () => {
        setLoading(true)
        dataProvider.getList(Resources.DICT_LOAN_TYPES.name,
            {sort: {field: 'sortOrder', order: 'ASC'}, filter: {includeDeleted: true}})
            .then(({data}) => {
                const loanTypesMap = convertArrayToObject(data, "id")
                setLoanTypes(loanTypesMap)
                setLoading(false)
            })
            .catch((err) => {
                setLoading(false)
                onError(err, notify)
            });
    }

    useEffect(() => {
        loadLoanTypes()
    }, [])

    useEffect(() => {
        loadData()
    }, [currency, createdDateFrom, createdDateTo, loanTypes]);

    const legendPlacement = {
        slotProps: {
            legend: {
                position: {
                    vertical: 'middle',
                    horizontal: 'right',
                },
                direction: 'column',
                itemGap: 2,
            },
        },
        margin: {
            top: 20,
            right: 100,
        },
    }

    return (
        <Grid container columnSpacing={1} rowSpacing={1} className={`dashboard-grid dashboard-grid-${theme}`}>
            <Grid item xs={8}>
                <div className="header">
                    <Typography variant="h12" sx={{width: "100%"}}>
                        Loan Applications Summary by Loan Type
                    </Typography>
                </div>
                <table>
                    <thead>
                    <tr>
                        <th>Loan Type</th>
                        <th>Total Amount of all Loan Applications</th>
                        <th>Number of Loan Applications</th>
                        <th>Average deal size</th>
                    </tr>
                    </thead>
                    <tbody>
                    {data?.map((e, idx) => (
                            <tr key={idx}>
                                <td>{loanTypes ? loanTypes[e.id]?.name : e.id}</td>
                                <td className="fmt-number">{formatAmount(currency, e.totalAmount, {maximumFractionDigits: 0})}</td>
                                <td className="fmt-number">{e.count}</td>
                                <td className="fmt-number">{formatAmount(currency, e.avgDealSize, {maximumFractionDigits: 0})}</td>
                            </tr>
                        )
                    )}
                    </tbody>
                    <tfoot>
                    {totalRow && <tr>
                        <td>{totalRow.id}</td>
                        <td className="fmt-number">{formatAmount(currency, totalRow.totalAmount, {maximumFractionDigits: 0})}</td>
                        <td className="fmt-number">{totalRow.count}</td>
                        <td className="fmt-number">{formatAmount(currency, totalRow.avgDealSize, {maximumFractionDigits: 0})}</td>
                    </tr>}
                    </tfoot>
                </table>
            </Grid>
            <Grid item xs={4}>
                <PieChart
                    series={[
                        {
                            data: graphData,
                        },
                    ]}
                    width={600}
                    // height={300}
                    colors={mangoFusionPalette}
                    {...legendPlacement}
                />
            </Grid>
        </Grid>
    )
}