import React from "react";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Dialog from "@mui/material/Dialog";
import {Button, Form, TextInput,} from "react-admin";
import DialogContentText from "@mui/material/DialogContentText";
import LoanApplicationTitle from "../controls/LoanApplicationTitle";

function DeactivateCreditorApplicationDialog(props) {
    const {close, record, open, submit} = props;

    return (
        <Dialog onClose={close} open={open} PaperProps={{
            sx: {
                width: "50%",
                maxHeight: 300
            }
        }}>
            <DialogTitle>Deactivate Debt Investor</DialogTitle>
            <Form record={{}} onSubmit={submit}>
                <DialogContent>
                    <DialogContentText>
                        Please provide reason for deactivating
                    </DialogContentText>
                    <TextInput multiline fullWidth label="Deactivate reason" source="reason"/>
                </DialogContent>
                <DialogActions>
                    <Button onClick={close} label="Cancel"/>
                    <Button type="submit" disabled={props.isLoading} label="Deactivate"/>
                </DialogActions>
            </Form>
        </Dialog>

    );
}

export default DeactivateCreditorApplicationDialog;
