import PropTypes from 'prop-types'
import React, {useEffect, useState} from "react";
import Resources from "../../Resources";
import {
    Datagrid,
    TextField,
    ListContextProvider,
    useGetList,
    useList,
    useDataProvider,
    useNotify,
    Button,
    FunctionField, useRefresh
} from "react-admin";
import {onError, onSuccess} from "../../common/utils";
import {useModal} from "../../common/hooks";
import CreateTermSheetDialog from "./CreateTermSheetDialog";
import UpdateTermSheetDialog from "./UpdateTermSheetDialog";
import {useQuery} from "@tanstack/react-query";
import Grid from "@mui/material/Grid";
import {TermSheetDictEnum, TermSheetDictMap, TermSheetVerificationStatus} from "../dicts/LoanApplicationEnum";
import EnumDictField from "../controls/EnumDictField";
import {TableCell, TableHead, TableRow} from "@mui/material";

function LoanApplicationTermSheet(props) {
    const { loanApplication } = props;

    const dataProvider = useDataProvider();
    const notify = useNotify();
    const refresh = useRefresh();
    const [CreateTermSheetModalDialog, showCreateTermSheetModalDialog] = useModal(CreateTermSheetDialog, {loanApplication})
    const [UpdateTermSheetModalDialog, showUpdateTermSheetModalDialog] = useModal(UpdateTermSheetDialog,
{
            loanApplication
        });

    const [termSheetMeta, setTermSheetMeta] = useState(null);

    const { data, isInitialLoading, error } = useQuery({
        queryKey: ['loanApplicationTermSheet', 'getOne', { id: loanApplication.id }],
        queryFn: () => {
            return dataProvider['doAction'](Resources.LOAN_APPLICATIONS.name, {
                action: `${Resources.LOAN_APPLICATIONS.subrequests.TERM_SHEET.name}`,
                id: loanApplication.id,
                method: 'GET'
            });
        }});
    useEffect(() => {
        setTermSheetMeta(data?.data.data.meta);
    }, [data]);
    // const termSheetMeta = data?.data.data.meta;
    const listContext = useList({ data: data?.data.data.rows });

    function EmptyToolBar(props) {
        return (
            <div>
                <Button label="Create Term Sheet" onClick={showCreateTermSheetModalDialog} />
            </div>
        );
    }

    function updateOfferValue(offerId, offerParamId, offerParamKey, offerParamValue, verificationStatus) {
        if(offerId) {
            showUpdateTermSheetModalDialog({offerId, offerParamId, offerParamKey, offerParamValue});
        }
    }

    function addOfferValue(offerId) {
        if(offerId) {
            showUpdateTermSheetModalDialog({offerId});
        }
    }

    function verifyOffer(offerId) {
        dataProvider['doAction'](Resources.LOAN_APPLICATIONS.name, {
            action: `${Resources.LOAN_APPLICATIONS.subrequests.TERM_SHEET.name}/${offerId}/${Resources.LOAN_APPLICATIONS.subrequests.TERM_SHEET.subrequests.VERIFY.name}`,
            id: loanApplication.id,
            method: "PUT",
        }).then(({data}) => {
            if (data.success) {
                refresh();
            } else {
                const msg =
                    data.errMessage
                        ? data.errMessage
                        : "System error";
                onError({message: msg}, notify)
            }
        }).catch(error => {
            onError(error, notify);
        });

    }

    function ColumnHeader(props) {
        const {column, index} = props;
        return (
            <>
                <Grid container flexDirection="column" >
                    <Grid item>
                        {column.label}
                    </Grid>
                    {index > 0 && <Grid item>
                        <Button onClick={() => addOfferValue(column.id)} label="Add value" variant="outlined" />
                    </Grid>}
                </Grid>
            </>
        );
    }

    const DatagridHeader = ({ meta }) => (
        <TableHead>
            <TableRow>
                {/* empty cell to account for the select row checkbox in the body */}
                {termSheetMeta?.map((column, i) => (
                        <TableCell key={column.label}><ColumnHeader column={column} index={i} /></TableCell>
                    )
                )}
            </TableRow>
        </TableHead>
    );
    return (
        <>
            <ListContextProvider value={listContext}>
                <Datagrid rowClick={false} header={<DatagridHeader meta={termSheetMeta} />} bulkActionButtons={false} empty={<EmptyToolBar />} >
                    {termSheetMeta?.map((column, i) => (
                        <FunctionField key={column.code} render={(record) => {return (
                            <>
                                {!record[column.code]?.dictType &&
                                    <TextField source={column.code + ".value"}
                                               emptyText="<no value>"
                                               onDoubleClick={() => updateOfferValue(column.id, record[column.code]?.id,
                                                   record.offerKey.value, record[column.code]?.value, column.verificationStatus)} />
                                }
                                {record[column.code]?.dictType &&
                                    <>
                                        {record[column.code].dictType === TermSheetDictEnum.VERIFICATION_STATUS_DICT &&
                                            record[column.code].value === TermSheetVerificationStatus.UNDER_REVIEW.code &&
                                            <Button variant="outlined" label="Confirm" onClick={() => verifyOffer(column.id)} />
                                        }
                                        {!(record[column.code].dictType === TermSheetDictEnum.VERIFICATION_STATUS_DICT &&
                                            record[column.code].value === TermSheetVerificationStatus.UNDER_REVIEW.code) &&
                                            <>
                                                <EnumDictField enum={TermSheetDictMap[record[column.code].dictType]} source={column.code + ".value"} />
                                            </>
                                        }
                                    </>
                                }

                            </>
                        )}} />
                    ))}
                </Datagrid>
            </ListContextProvider>
            {CreateTermSheetModalDialog}
            {UpdateTermSheetModalDialog}
        </>
    );
}

export default LoanApplicationTermSheet;

LoanApplicationTermSheet.propTypes = {
  loanApplication: PropTypes.object.isRequired
}