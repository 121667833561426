import {BulkDeleteWithConfirmButton, Datagrid, EditButton, ShowButton, TextField, usePermissions} from "react-admin";
import DictField from "./DictField";
import LegalEntityRelDetails from "./LegalEntityRelDetails";
import Resources from "../../Resources";
import {haveRole} from "../../common/utils";
import {AdminPortalRoles} from "../dicts/Security";
import * as React from "react";

const LegalEntitiesDatagrid = (props) => {
    const { permissions } = usePermissions();
    return (
        <Datagrid rowClick={false} data-testid="legal-entities-list-grid"
                  bulkActionButtons={haveRole(permissions, AdminPortalRoles.BACKOFFICE_SENIOR_MGMT)
                      ? <BulkDeleteWithConfirmButton confirmContent="You will not be able to recover this records. Are you sure?"/>
                      : false}>
            <TextField source="id"/>
            <TextField source="fullCompanyName" label="Company Name"/>
            <TextField source="registrationNumber" label="Registration number"/>
            <TextField source="website" label="Website"/>
            <DictField source="legalFormCode" label="Legal Form" reference={Resources.DICT_LEGAL_FORMS.name}/>
            {props.withRelations && <LegalEntityRelDetails source="relationType" />}
            <EditButton/>
            <ShowButton/>
        </Datagrid>
    );
}

export default LegalEntitiesDatagrid;
