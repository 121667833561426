import {SelectField, SimpleShowLayout, TextField, useTheme} from "react-admin";
import * as React from "react";
import DateTimeFieldTZ from "../controls/DateFieldTZ";
import {JsonField} from "react-admin-json-view";
import {CreditorApplicationStatus} from "../dicts/CreditorApplicationEnums";

function CreditorApplicationDetails(props) {
    const {record} = props;
    const [theme] = useTheme();
    return (
        <SimpleShowLayout record={record}>
            <DateTimeFieldTZ source="createdDate" label={"Created Date (London)"} />
            <SelectField source="statusCode" label="Status"
                         choices={Array.from(Object.keys(CreditorApplicationStatus),
                             (key) => ({
                                 id: CreditorApplicationStatus[key].code,
                                 name: CreditorApplicationStatus[key].label
                             }))}
            />
            <TextField source="deactivateReason" aria-multiline/>
            <JsonField
                source="rawdata"
                label="Raw application data"
                jsonString={false} // Set to true if the value is a string, default: false
                reactJsonOptions={{
                    // Props passed to react-json-view
                    name: null,
                    collapsed: true,
                    enableClipboard: false,
                    displayDataTypes: false,
                    theme: theme === "dark" ? "monokai" : "rjv-default",
                }}
            />
        </SimpleShowLayout>
    );
}

export default CreditorApplicationDetails;
