import * as React from "react";
import {useCallback, useEffect, useMemo, useState} from "react";
import {convertArrayToObject, getDateMinusMonths, onError} from "../../common/utils";
import Grid from "@mui/material/Grid";
import {DateInput, Form, SaveButton, useDataProvider, useNotify} from "react-admin";
import {CurrencyInput} from "../controls/CurrencyInput";
import {CurrencyType} from "../dicts/ExchangeEnums";
import {LegalEntitiesByAum} from "./LegalEntitiesByAum";
import Resources from "../../Resources";

const LegalEntitiesFilterForm = (props) => {
    return (
        <Grid container columnSpacing={1} rowSpacing={1}>
            <Grid item xs={3}>
                <CurrencyInput source="currency" required currencyTypeCode={CurrencyType.FIAT.code}/>
            </Grid>
            <Grid item xs={2}>
                <DateInput label="Relationship Start Date (from)" source="operatedDateFrom" required/>
            </Grid>
            <Grid item xs={2}>
                <DateInput label="Relationship Start Date (to)" source="operatedDateTo" required/>
            </Grid>
            <Grid item xs={1}>
                <SaveButton color="primary" size="large" variant="contained" sx={{marginTop: "10px"}} label="Apply"/>
            </Grid>
        </Grid>
    )
}

export const LegalEntitiesSummary = (props) => {
    //todo use loading
    const [loading, setLoading] = useState(false)
    const dataProvider = useDataProvider();
    const notify = useNotify();

    const defaultValues = useMemo(
        () => ({currency: "USD", operatedDateFrom: getDateMinusMonths(1), operatedDateTo: new Date()}),
        []
    )
    const [filterValues, setFilterValues] = useState(defaultValues)
    const [aum, setAum] = useState([])

    const onLegalEntitiesFilterSubmit = useCallback(
        (values) => setFilterValues({...values}),
        [setFilterValues]
    );
    const validate = (form) => {
        const errors = {};
        if (!form.currency) {
            errors.currency = "Currency is required";
        }
        if (!form.operatedDateFrom) {
            errors.operatedDateFrom = "Relationship Start Date (From) is required";
        }
        if (!form.operatedDateTo) {
            errors.operatedDateTo = "Relationship Start Date (To) is required";
        }
        return errors;
    }

    const loadAumDict = () => {
        setLoading(true)
        dataProvider.getList(Resources.DICT_ASSETS_UNDER_MGMT.name,
            {sort: {field: 'sortOrder', order: 'ASC'}})
            .then(({data}) => {
                // console.log("bc", data)
                const bcMap = convertArrayToObject(data, "id")
                bcMap[0] = {id: 0, code: "-", name: "N/A"}
                // console.log("bcMap", bcMap)
                setAum(bcMap)
                setLoading(false)
            })
            .catch((err) => {
                setLoading(false)
                onError(err, notify)
            });
    }

    useEffect(() => {
        loadAumDict()
    }, [])

    return (
        <Form defaultValues={defaultValues} onSubmit={onLegalEntitiesFilterSubmit} validate={validate}>
            <Grid container columnSpacing={1} rowSpacing={1} className="dashboard-grid">
                <Grid item xs={11}>
                    <LegalEntitiesFilterForm/>
                </Grid>
                <Grid item xs={11}>
                    <LegalEntitiesByAum {...filterValues}
                                        dict={aum}
                                        label="Legal Entities Amount by AUM"/>
                </Grid>
            </Grid>
        </Form>
    )
}