import {DateField, DateInput, FunctionField, TextField, useTheme} from "react-admin";
import {SimpleShowLayout} from "ra-ui-materialui";
import * as React from "react";
import getCountries, {getCountryByCode} from "../../common/countries";
import Typography from "@mui/material/Typography";
import {useEffect, useState} from "react";
import DateTimeFieldTZ from "../controls/DateFieldTZ";
import FormattedAmountField from "../controls/FormattedAmountField";
import DictField from "../controls/DictField";
import Resources from "../../Resources";

function LegalEntityDetails(props) {
    const {record} = props;
    const [registeredOfficeCountry, setRegisteredOfficeCountry] = useState(null);
    const [primaryBusinessCountry, setPrimaryBusinessCountry] = useState(null);
    const countries = getCountries();
    const [theme] = useTheme();

    useEffect(()=> {
        let roc;
        let pbc;
        roc = record?.registeredOfficeAddress?.country;
        pbc = record?.primaryBusinessAddress?.country;
        const registeredOfficeFilteredCountry = countries.filter((country) => country[0] == record?.registeredOfficeAddress?.country);
        if (registeredOfficeFilteredCountry.length > 0) {
           roc = registeredOfficeFilteredCountry[0][1];
        }
        const primaryOfficeFilteredCountry = countries.filter((country) => country[0] == record?.primaryBusinessAddress?.country);
        if (primaryOfficeFilteredCountry.length > 0) {
            pbc = primaryOfficeFilteredCountry[0][1];
        }
        setRegisteredOfficeCountry(roc);
        setPrimaryBusinessCountry(pbc);
    }, [record]);

    if (!record) {
        return <></>
    }

    return (
        <SimpleShowLayout>
            <DateField source="relationshipStartDate"/>
            <TextField record={record} label="Full Company Name" source="fullCompanyName"/>
            <TextField record={record} label="Registration number" source="registrationNumber"/>
            <FormattedAmountField
                label="Assets under management"
                source="assetsUnderMgmt"
                currencyAttr="assetsUnderMgmtCurrency"
                options={{maximumFractionDigits: 0}}
            />
            <DictField source="businessCategoryCode" label="Business Category"
                       reference={Resources.DICT_BUSINESS_CATEGORIES.name}/>
            <DictField source="originationSourceCode" label="Origination Source"
                       reference={Resources.DICT_ORIGINATION_SOURCES.name}/>
            {record.registeredOfficeAddress === undefined &&
                <>
                    <Typography className={`form-field-label form-field-label-${theme}`}>Registered Office address:</Typography>
                    <Typography className={`form-field-value form-field-value-${theme}`}>N/A</Typography>
                </>
            }
            {record.registeredOfficeAddress &&
                <TextField record={record} label="Registered Office, address line 1"
                           source="registeredOfficeAddress.addressLine1"/>
            }
            {record.registeredOfficeAddress && <TextField record={record} label="Registered Office, address line 2"
                                                          source="registeredOfficeAddress.addressLine2"/>
            }
            {record.registeredOfficeAddress && <TextField record={record} label="Registered Office, city"
                                                          source="registeredOfficeAddress.city"/>
            }
            {record.registeredOfficeAddress && <TextField record={record} label="Registered Office, state/province"
                                                          source="registeredOfficeAddress.stateProvince"/>
            }
            {record.registeredOfficeAddress && <TextField record={record} label="Registered Office, postal code"
                                                          source="registeredOfficeAddress.postalCode"/>
            }
            {record.registeredOfficeAddress &&
                <FunctionField label="Registered Office, Country"
                               source="registeredOfficeAddress.country"
                               render={(record) => <>{getCountryByCode(registeredOfficeCountry, countries)}</>}/>
            }
            {record.primaryBusinessAddress === undefined &&
                <>
                    <Typography className={`form-field-label form-field-label-${theme}`}>Primary Business address:</Typography>
                    <Typography className={`form-field-value form-field-value-${theme}`}>N/A</Typography>
                </>
            }
            {record.primaryBusinessAddress &&
                <TextField record={record} label="Primary business address, address line 1"
                           source="primaryBusinessAddress.addressLine1"/>
            }
            {record.primaryBusinessAddress &&
                <TextField record={record} label="Primary business address, address line 2"
                           source="primaryBusinessAddress.addressLine2"/>
            }
            {record.primaryBusinessAddress &&
                <TextField record={record} label="Primary business address, city"
                           source="primaryBusinessAddress.city"/>
            }
            {record.primaryBusinessAddress &&
                <TextField record={record} label="Primary business address, state/province"
                           source="primaryBusinessAddress.stateProvince"/>
            }
            {record.primaryBusinessAddress &&
                <TextField record={record} label="Primary business address, postal code"
                           source="primaryBusinessAddress.postalCode"/>
            }
            {record.primaryBusinessAddress &&
                <FunctionField label="Primary business Office, Country"
                               source="primaryBusinessAddress.country"
                               render={(record) => <>{getCountryByCode(primaryBusinessCountry, countries)}</>}/>
            }

            <TextField record={record} label="Description" source="description"/>
            <TextField record={record} label="Website" source="website"/>
            <DateTimeFieldTZ source="createdDate" label={"Created Date (London)"}/>
        </SimpleShowLayout>
    )
}

export default LegalEntityDetails;
