export const LoanApplicationStatus = {
    DRAFT: {
        code: "DRAFT", label: "Draft",
        desc: "Loan application was drafted"
    },
    SUBMITTED: {
        code: "SUBMITTED", label: "Submitted",
        desc: "Loan application was created"
    },
    PROCESSING: {
        code: "PROCESSING", label: "Processing",
        desc: "Loan application is being analyzed"
    },
    REJECTED: {
        code: "REJECTED", label: "Rejected",
        desc: "Loan application has been rejected",
    },
    SOURCING_OF_CAPITAL: {
        code: "SOURCING_OF_CAPITAL", label: "Sourcing of Capital",
        desc: "Loan application is waiting for capital sourcing"
    },
    FUNDED: {
        code: "FUNDED", label: "Funded",
        desc: "Loan application was funded by a Debt Investor"
    },
    APPROVED: {
        code: "APPROVED", label: "Approved",
        desc: "Loan application was approved by Monivolt"
    },
    INACTIVE: {
        code: "INACTIVE", label: "Inactive",
        desc: "Loan application was inactivated by Monivolt"
    }
}

export const DataRoomItemType = {
    SET: 'SET',
    FOLDER: 'FOLDER',
    LOAN_APPLICATION_DOCUMENT: 'LOAN_APPLICATION_DOCUMENT'
}

export const DATA_ROOM_SET_ROOT = '<root>';
export const DATA_ROOM_PROTECTED_SET = 'protected';

export const DataroomAuditEventType = {
    DATAROOM_DOCUMENT_DOWNLOAD: {
        code: 'DATAROOM_DOCUMENT_DOWNLOAD',
        label: 'Document downloading',
        desc: 'User tries to download document from Dataroom'
    },
    DATAROOM_ARCHIVE_DOWNLOAD: {
        code: 'DATAROOM_ARCHIVE_DOWNLOAD',
        label: 'Archive downloading',
        desc: 'User tries to download the archive with documents from Dataroom'
    },
    DATAROOM_ARCHIVE_CREATE: {
        code: 'DATAROOM_ARCHIVE_CREATE',
        label: 'Archive creating',
        desc: 'User tries to create archive with folder content to download'
    },
    DATAROOM_DOC_UPLOADED: {
        code: 'DATAROOM_DOC_UPLOADED',
        label: 'Document uploaded',
        desc: 'User uploads document to Dataroom'
    },
    DATAROOM_DOC_MOVED: {
        code: 'DATAROOM_DOC_MOVED',
        label: 'Document moved',
        desc: 'User moves document from one folder to another'
    },
    DATAROOM_DOC_DELETED: {
        code: 'DATAROOM_DOC_DELETED',
        label: 'Document deleted',
        desc: 'User deleted document'
    },
    DATAROOM_DOC_RESTORED: {
        code: 'DATAROOM_DOC_RESTORED',
        label: 'Document restored',
        desc: 'Backoffice operator restored the document'
    },
}

export const TermSheetVerificationStatus = {
    UNDER_REVIEW: {
        code: 'UNDER_REVIEW',
        label: 'Under Review',
    },
    VERIFIED: {
        code: 'VERIFIED',
        label: 'Verified',
    }
};

export const TermSheetAcceptanceStatus = {
    UNDER_REVIEW: {
        code: 'UNDER_REVIEW',
        label: 'Under Review',
    },
    ACCEPTED: {
        code: 'ACCEPTED',
        label: 'Accepted',
    },
    COUNTER_OFFERED: {
        code: 'COUNTER_OFFERED',
        label: 'Counter Offered',
    },
    DECLINED: {
        code: 'DECLINED',
        label: 'Declined',
    },
    CLOSED: {
        code: 'CLOSED',
        label: 'Closed',
    },
    CANCELLED: {
        code: 'CANCELLED',
        label: 'Cancelled',
    },
    NOT_SELECTED: {
        code: 'NOT_SELECTED',
        label: 'Not Selected',
    },
    IMPLICITLY_ACCEPTED: {
        code: 'IMPLICITLY_ACCEPTED',
        label: 'Implicitly Accepted',
    }
};

export const CounterpartyType = {
    BORROWER: {
        code: 'BORROWER',
        label: 'Borrower',
    },
    CREDITOR: {
        code: 'CREDITOR',
        label: 'Debt Investor',
    }
};

export const TermSheetDictEnum = {
    INITIATOR_DICT: 'INITIATOR_DICT',
    ACCEPTANCE_STATUS_DICT: 'ACCEPTANCE_STATUS_DICT',
    VERIFICATION_STATUS_DICT: 'VERIFICATION_STATUS_DICT',
};

export const TermSheetDictMap = {
    [TermSheetDictEnum.INITIATOR_DICT]: CounterpartyType,
    [TermSheetDictEnum.ACCEPTANCE_STATUS_DICT]: TermSheetAcceptanceStatus,
    [TermSheetDictEnum.VERIFICATION_STATUS_DICT]: TermSheetVerificationStatus
}