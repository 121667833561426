import {
    DateInput,
    DeleteWithConfirmButton,
    SaveButton,
    SimpleForm,
    TextInput,
    Toolbar,
    ToolbarClasses,
    usePermissions
} from "react-admin";
import Grid from "@mui/material/Grid";
import AddressInput from "../controls/AddressInput";
import Resources from "../../Resources";
import {CurrencyInput} from "../controls/CurrencyInput";
import React from "react";
import {FormattedNumberInput} from "../controls/FormattedNumberInput";
import DictInput from "../controls/DictInput";
import {CurrencyType} from "../dicts/ExchangeEnums";
import {CheckPermission} from "../security/CheckPermission";
import {AdminPortalRoles} from "../dicts/Security";

function LegalEntitiesForm(props) {
    const validateForm = (form) => {
        const errors = {};
        if(form.description?.length > 2000) {
            errors.description = "Description must be less or equal 2000 symbols";
        }
        return errors;
    }
    const EditToolbar = (props) => (
        <Toolbar {...props} >
            <div className={ToolbarClasses.defaultToolbar}>
                <SaveButton/>
                <CheckPermission role={AdminPortalRoles.BACKOFFICE_SENIOR_MGMT}>
                    <DeleteWithConfirmButton/>
                </CheckPermission>
            </div>
        </Toolbar>
    );

    return (
        <SimpleForm validate={validateForm} toolbar={<EditToolbar/>}>
            <Grid container spacing={1} maxWidth="75%">
                <Grid item xs={2}>
                    <DateInput source="relationshipStartDate"/>
                </Grid>
                <Grid item xs={5}>
                    <TextInput source="fullCompanyName" fullWidth/>
                </Grid>
                <Grid item xs={5}>
                    <TextInput source="registrationNumber" fullWidth/>
                </Grid>
                <Grid item xs={3}>
                    <CurrencyInput label="Currency of Assets under management"
                                   source="assetsUnderMgmtCurrency"
                                   reference={Resources.DICT_CURRENCIES.name}
                                   currencyTypeCode={CurrencyType.FIAT.code}
                                   fullWidth
                    />
                </Grid>
                <Grid item xs={3}>
                    <FormattedNumberInput label="Amount of Assets under management" source="assetsUnderMgmt" fullWidth/>
                </Grid>
                <Grid item xs={3}>
                    <DictInput label="Business Category" source="businessCategoryCode"
                               reference={Resources.DICT_BUSINESS_CATEGORIES.name}
                               fullWidth
                    />
                </Grid>
                <Grid item xs={3}>
                    <DictInput label="Origination Source" source="originationSourceCode"
                               reference={Resources.DICT_ORIGINATION_SOURCES.name}
                               fullWidth
                    />
                </Grid>
                <AddressInput source="registeredOfficeAddress" />
                <AddressInput source="primaryBusinessAddress" />
                <Grid item xs={12}>
                    <TextInput source="description" fullWidth/>
                </Grid>
                <Grid item xs={4}>
                    <TextInput source="website" fullWidth/>
                </Grid>
            </Grid>
        </SimpleForm>
    );
}

export default LegalEntitiesForm;