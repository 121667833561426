import {BooleanField, DateField, NumberField, SimpleShowLayout, TextField, useTheme} from "react-admin";
import DictField from "../controls/DictField";
import Resources from "../../Resources";
import * as React from "react";
import DateTimeFieldTZ from "../controls/DateFieldTZ";
import FormattedAmountField from "../controls/FormattedAmountField";
import {JsonField} from "react-admin-json-view";
import {LoanApplicationStatus} from "../dicts/LoanApplicationEnum";
import EnumDictField from "../controls/EnumDictField";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

function LoanApplicationDetails(props) {
    const {record} = props;
    const [theme] = useTheme();
    return (
        <SimpleShowLayout record={record} className="show-layout-plain">
            <Grid container spacing={1}>
                <Grid item xs={12} className={`grid-fs grid-fs-${theme}`}>
                    <Typography className={`grid-label grid-label-${theme}`}>Basic Info</Typography>
                    <Grid container spacing={1} className="form-section">
                        <Grid item xs={2}>
                            <Typography className={`form-field-label form-field-label-${theme}`}>Created
                                Date:</Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <DateTimeFieldTZ source="createdDate" label={"Created Date (London)"}/>
                        </Grid>
                        <Grid item xs={2}>
                            <Typography className={`form-field-label form-field-label-${theme}`}>Targeted
                                Closure:</Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <DateField source="targetedClosure" showTime={false}/>
                        </Grid>
                        <Grid item xs={2}>
                            <Typography className={`form-field-label form-field-label-${theme}`}>Loan Type:</Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <DictField source="loanTypeCode" label="Loan Type"
                                       reference={Resources.DICT_LOAN_TYPES.name}/>
                        </Grid>
                        <Grid item xs={2}>
                            <Typography className={`form-field-label form-field-label-${theme}`}>Loan
                                Amount:</Typography>
                        </Grid>
                        <Grid item xs={4} data-testid="loanAmount">
                            <FormattedAmountField
                                label="Loan Amount"
                                source="loanAmount"
                                currencyAttr="currency"
                                options={{maximumFractionDigits: 0}}
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <Typography className={`form-field-label form-field-label-${theme}`}>Loan Term
                                (months):</Typography>
                        </Grid>
                        <Grid item xs={4} data-testid="loanTerm">
                            <TextField label="Loan Term (months)" source="loanTerm"/>
                        </Grid>
                        <Grid item xs={2}>
                            <Typography className={`form-field-label form-field-label-${theme}`}>Region:</Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <TextField label="Region" source="regionCode"/>
                        </Grid>
                        <Grid item xs={2}>
                            <Typography className={`form-field-label form-field-label-${theme}`}>Status:</Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <EnumDictField source="statusCode" enum={LoanApplicationStatus}/>
                        </Grid>
                        {record?.statusCode === LoanApplicationStatus.INACTIVE.code && <>
                            <Grid item xs={2}>
                                <Typography className={`form-field-label form-field-label-${theme}`}>Inactive
                                    Comment:</Typography>
                            </Grid>
                            <Grid item xs={4}>
                                <TextField source="inactiveComment" defaultValue="-"/>
                            </Grid>
                        </>}
                        {record?.statusCode === LoanApplicationStatus.REJECTED.code && <>
                            <Grid item xs={2}>
                                <Typography className={`form-field-label form-field-label-${theme}`}>Internal Rationale:</Typography>
                            </Grid>
                            <Grid item xs={4}>
                                <TextField component="pre" source="internalRationale"/>
                            </Grid>
                        </>}
                        {record?.statusCode === LoanApplicationStatus.REJECTED.code && <>
                            <Grid item xs={2}>
                                <Typography className={`form-field-label form-field-label-${theme}`}>Comment for Customer:</Typography>
                            </Grid>
                            <Grid item xs={4}>
                                <TextField component="pre" source="commentForCustomer"/>
                            </Grid>
                        </>}
                        <Grid item xs={2}>
                            <Typography className={`form-field-label form-field-label-${theme}`}>Initiator
                                Login:</Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <TextField source="initiatorLogin"/>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} className={`grid-fs grid-fs-${theme}`}>
                    <Typography className={`grid-label grid-label-${theme}`}>Borrower Info</Typography>
                    <Grid container spacing={1} className="form-section">
                        <Grid item xs={2}>
                            <Typography className={`form-field-label form-field-label-${theme}`}>Borrower
                                Type:</Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <DictField source="borrowerTypeCode" label="Borrower Type"
                                       reference={Resources.DICT_BORROWER_TYPES.name}/>
                        </Grid>
                        <Grid item xs={2}>
                            <Typography className={`form-field-label form-field-label-${theme}`}>Sector:</Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <DictField source="sectorCode" label="Sector" reference={Resources.DICT_SECTORS.name}/>
                        </Grid>
                        <Grid item xs={2}>
                            <Typography className={`form-field-label form-field-label-${theme}`}>
                                Indicative Return From, %:</Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <NumberField source="irrFrom"/>
                        </Grid>
                        <Grid item xs={2}>
                            <Typography className={`form-field-label form-field-label-${theme}`}>
                                Indicative Return To, %:</Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <NumberField source="irrTo"/>
                        </Grid>
                        <Grid item xs={2}>
                            <Typography className={`form-field-label form-field-label-${theme}`}>
                                Returns From, Money Multiple:</Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <NumberField source="irmmFrom"/>
                        </Grid>
                        <Grid item xs={2}>
                            <Typography className={`form-field-label form-field-label-${theme}`}>
                                Returns To, Money Multiple:</Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <NumberField source="irmmTo"/>
                        </Grid>
                        <Grid item xs={2}>
                            <Typography className={`form-field-label form-field-label-${theme}`}>Revenue:</Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <FormattedAmountField
                                source="revenue"
                                currencyAttr="currency"
                                options={{maximumFractionDigits: 0}}
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <Typography className={`form-field-label form-field-label-${theme}`}>EBITDA:</Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <FormattedAmountField
                                source="ebitda"
                                currencyAttr="currency"
                                options={{maximumFractionDigits: 0}}
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <Typography className={`form-field-label form-field-label-${theme}`}>Enterprise
                                Value:</Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <FormattedAmountField
                                source="enterpriseValue"
                                currencyAttr="currency"
                                options={{maximumFractionDigits: 0}}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12} className={`grid-fs grid-fs-${theme}`}>
                        <Typography className={`grid-label grid-label-${theme}`}>Additional Info</Typography>
                        <Grid container spacing={1} className="form-section">
                            <Grid item xs={2}>
                                <Typography className={`form-field-label form-field-label-${theme}`}>Additional
                                    Information:</Typography>
                            </Grid>
                            <Grid item xs={10} data-testid="additionalInfo">
                                <TextField source="additionalInfo"/>
                            </Grid>
                            <Grid item xs={2}>
                                <Typography className={`form-field-label form-field-label-${theme}`}>Use of
                                    Funds:</Typography>
                            </Grid>
                            <Grid item xs={4}>
                                <DictField source="useOfFundsCode" label="Use of Funds"
                                           reference={Resources.DICT_USE_OF_FUNDS.name}/>
                            </Grid>
                            <Grid item xs={2}>
                                <Typography className={`form-field-label form-field-label-${theme}`}>Loan
                                    Structure:</Typography>
                            </Grid>
                            <Grid item xs={4}>
                                <DictField source="loanStructureCode" label="Loan Structure"
                                           reference={Resources.DICT_LOAN_STRUCTURES.name}/>
                            </Grid>
                            <Grid item xs={2}>
                                <Typography className={`form-field-label form-field-label-${theme}`}>Business
                                    Stage:</Typography>
                            </Grid>
                            <Grid item xs={4}>
                                <DictField source="businessStageCode" label="Business Stage"
                                           reference={Resources.DICT_BUSINESS_STAGES.name}/>
                            </Grid>
                            <Grid item xs={2}>
                                <Typography className={`form-field-label form-field-label-${theme}`}>Needs Borrower
                                    Confirmation?</Typography>
                            </Grid>
                            <Grid item xs={4}>
                                <BooleanField source="isBorrowerConfirmationRequired"/>
                            </Grid>
                            <Grid item xs={2}>
                                <Typography className={`form-field-label form-field-label-${theme}`}>Is watermark
                                    required on documents?</Typography>
                            </Grid>
                            <Grid item xs={4}>
                                <BooleanField source="isWatermarkRequired"/>
                            </Grid>
                            <Grid item xs={2}>
                                <Typography className={`form-field-label form-field-label-${theme}`}>Automatically
                                    verify counterparty offers?</Typography>
                            </Grid>
                            <Grid item xs={4}>
                                <BooleanField source="autoVerifyOffers"/>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} className={`grid-fs grid-fs-${theme} form-section`}>
                        <Typography className={`grid-label grid-label-${theme}`}>Raw Data</Typography>
                        <JsonField
                            source="rawdata"
                            jsonString={false} // Set to true if the value is a string, default: false
                            reactJsonOptions={{
                                // Props passed to react-json-view
                                name: null,
                                collapsed: true,
                                enableClipboard: false,
                                displayDataTypes: false,
                                theme: theme === "dark" ? "monokai" : "rjv-default",
                            }}
                        />
                    </Grid>
                    {/*<DictField source="assetClassCode" label="Asset Class" reference={Resources.DICT_ASSET_CLASSES.name}/>*/}
                </Grid>
            </Grid>
        </SimpleShowLayout>
    );
}

export default LoanApplicationDetails;
