import PropTypes from 'prop-types'
import {BooleanField, BulkDeleteWithConfirmButton, Datagrid, TextField, usePermissions} from "react-admin";
import * as React from "react";
import PersonRelDetails from "../controls/PersonRelDetails";
import ShowButtonWrapper from "../controls/ShowButtonWrapper";
import PersonEmailField from "../controls/PersonEmailField";
import EditButtonWrapper from "../controls/EditButtonWrapper";
import {haveRole} from "../../common/utils";
import {AdminPortalRoles} from "../dicts/Security";

function PersonsDatagrid(props) {
    const { permissions } = usePermissions();
    return (
        <Datagrid rowClick={false} data-testid="persons-list-grid"
                  bulkActionButtons={haveRole(permissions, AdminPortalRoles.BACKOFFICE_SENIOR_MGMT)
                      ? <BulkDeleteWithConfirmButton confirmContent="You will not be able to recover this records. Are you sure?"/>
                      : false}>
            <TextField source="id"/>
            <TextField source="firstName"/>
            <TextField source="lastName"/>
            {/*<TextField source="email"/>*/}
            <PersonEmailField source="email" verifiedSource="emailVerified" label="Email" />
            <TextField source="userLogin"/>
            <BooleanField label="Monivolt Representative" source="monivoltRepresentative"/>
            <TextField source="phoneNumber"/>
            <TextField source="title"/>
            {props.withRelations && <PersonRelDetails source="customerPersonRelations" />}
            {/*{props.withRelations && <TextField source="customerPersonRelations[0].relationType"/>}*/}
            {/*{props.withRelations && <TextField source="customerPersonRelations[0].relationTitle"/>}*/}
            {/*{props.withRelations && <TextField source="customerPersonRelations[0].shareAmount"/>}*/}
            {!props.overrideEditButton && <EditButtonWrapper />}
            {props.overrideEditButton && props.overrideEditButton}
            <ShowButtonWrapper />
        </Datagrid>

    );
}

export default PersonsDatagrid;

PersonsDatagrid.propTypes = {
  overrideEditButton: PropTypes.node,
  withRelations: PropTypes.bool
}