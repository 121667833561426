import * as React from "react";
import {useCallback, useEffect, useMemo, useState} from "react";
import {convertArrayToObject, getDateMinusMonths, onError} from "../../common/utils";
import Grid from "@mui/material/Grid";
import {DateInput, Form, SaveButton, useDataProvider, useNotify} from "react-admin";
import {CurrencyInput} from "../controls/CurrencyInput";
import {CurrencyType} from "../dicts/ExchangeEnums";
import DictInput from "../controls/DictInput";
import Resources from "../../Resources";
import {DebtInvestorsBySmth} from "./DebtInvestorsBySmth";

export const DebtInvestorsSummary = (props) => {
    //todo use loading
    const [loading, setLoading] = useState(false)
    const dataProvider = useDataProvider();
    const notify = useNotify();

    // filter form
    const defaultValues = useMemo(
        () => ({currency: "USD",
                             relationshipStartDateFrom: getDateMinusMonths(1),
                             relationshipStartDateTo: new Date()}),
        []
    )
    const [aumFrom, setAumFrom] = useState(0)
    const [aumTo, setAumTo] = useState(0)
    const [filterValues, setFilterValues] = useState(defaultValues)
    // dicts
    const [businessCategories, setBusinessCategories] = useState([])
    const [originationSources, setOriginationSources] = useState([])

    const onDebtInvestorsFilterSubmit = useCallback(
        (values) => {
            const vv = {...values, ...{aumFrom, aumTo}}
            setFilterValues(vv)
        },
        [setFilterValues, aumFrom, aumTo]
    );

    const validate = (form) => {
        const errors = {};
        if (form.assetsUnderMgmt && !form.currency) {
            errors.currency = "Currency is required";
        }
        if (!form.relationshipStartDateFrom) {
            errors.relationshipStartDateFrom = "Relationship Start Date (From) is required";
        }
        if (!form.relationshipStartDateTo) {
            errors.relationshipStartDateTo = "Relationship Start Date (To) is required";
        }
        return errors;
    }

    const loadBusinessCategories = () => {
        setLoading(true)
        dataProvider.getList(Resources.DICT_BUSINESS_CATEGORIES.name,
            {sort: {field: 'sortOrder', order: 'ASC'}, filter: {includeDeleted: true}})
            .then(({data}) => {
                // console.log("bc", data)
                const bcMap = convertArrayToObject(data, "id")
                bcMap["-"] = {id: "-", code: "-", name: "N/A"}
                // console.log("bcMap", bcMap)
                setBusinessCategories(bcMap)
                setLoading(false)
            })
            .catch((err) => {
                setLoading(false)
                onError(err, notify)
            });
    }

    const loadOriginalSources = () => {
        setLoading(true)
        dataProvider.getList(Resources.DICT_ORIGINATION_SOURCES.name,
            {sort: {field: 'sortOrder', order: 'ASC'}, filter: {includeDeleted: true}})
            .then(({data}) => {
                // console.log("bc", data)
                const bcMap = convertArrayToObject(data, "id")
                bcMap["-"] = {id: "-", code: "-", name: "N/A"}
                // console.log("bcMap", bcMap)
                setOriginationSources(bcMap)
                setLoading(false)
            })
            .catch((err) => {
                setLoading(false)
                onError(err, notify)
            });
    }

    useEffect(() => {
        loadBusinessCategories()
        loadOriginalSources()
    }, [])

    const DebtInvestorsFilterForm = (props) => {
        const onAumChange = (event, values) => {
            const v = event.target.value;
            const dict = values.find((e) => e.id === v);
            if (dict) {
                setAumFrom(dict.amountFromIncl);
                setAumTo(dict.amountToExcl);
            } else {
                setAumFrom(null)
                setAumTo(null)
            }
        }

        return (
            <Grid container columnSpacing={1} rowSpacing={1}>
                <Grid item xs={2}>
                    <CurrencyInput source="currency" currencyTypeCode={CurrencyType.FIAT.code}/>
                </Grid>
                <Grid item xs={2}>
                    <DictInput source="assetsUnderMgmt"
                               reference={Resources.DICT_ASSETS_UNDER_MGMT.name}
                               fullWidth
                               optionValue="id"
                               onChange={onAumChange}
                    />
                </Grid>
                <Grid item xs={2}>
                    <DateInput label="Relationship Start Date (from)" source="relationshipStartDateFrom" required/>
                </Grid>
                <Grid item xs={2}>
                    <DateInput label="Relationship Start Date (to)" source="relationshipStartDateTo" required/>
                </Grid>
                <Grid item xs={1}>
                    <SaveButton color="primary" size="large" variant="contained" sx={{marginTop: "10px"}} label="Apply"/>
                </Grid>
            </Grid>
        )
    }

    return (
        <Form defaultValues={defaultValues} onSubmit={onDebtInvestorsFilterSubmit} validate={validate}>
            <Grid container columnSpacing={1} rowSpacing={1} className="dashboard-grid">
                <Grid item xs={11}>
                    <DebtInvestorsFilterForm />
                </Grid>
                <Grid item xs={6}>
                    <DebtInvestorsBySmth {...filterValues}
                                         dict={businessCategories}
                                         label="Monthly Increase by Category"
                                         resource={Resources.DASHBOARD.DEBT_INVESTORS_MONTHLY_INCREASE_BY_CATEGORY.name}
                    />
                </Grid>
                <Grid item xs={6}>
                    <DebtInvestorsBySmth {...filterValues}
                                         dict={originationSources}
                                         label="Monthly Increasy by Channel"
                                         resource={Resources.DASHBOARD.DEBT_INVESTORS_MONTHLY_INCREASE_BY_CHANNEL.name}
                    />
                </Grid>
                <Grid item xs={6}>
                    <DebtInvestorsBySmth cumulative
                                         {...filterValues}
                                         dict={businessCategories}
                                         label="Monthly Growth by Category (Cumulative)"
                                         resource={Resources.DASHBOARD.DEBT_INVESTORS_MONTHLY_INCREASE_BY_CATEGORY_CUMULATIVE.name}
                    />
                </Grid>
                <Grid item xs={6}>
                    <DebtInvestorsBySmth cumulative
                                         {...filterValues}
                                         dict={originationSources}
                                         label="Monthly Growth by Channel (Cumulative)"
                                         resource={Resources.DASHBOARD.DEBT_INVESTORS_MONTHLY_INCREASE_BY_CHANNEL_CUMULATIVE.name}
                    />
                </Grid>
            </Grid>
        </Form>
    )
}