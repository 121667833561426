import PropTypes from 'prop-types'
import React from "react";
import {SelectArrayInput, SelectInput, useChoicesContext} from "react-admin";
import {useFormContext} from "react-hook-form";
import {SELECT_ALL_VALUE} from "../../common/constants";

const SelectInputWithChoiceContext = (props) => {
    const {availableChoices} = useChoicesContext();
    const { setValue} = useFormContext()

    const onChange = (event) => {
        if (event.target.value.indexOf && event.target.value.indexOf(SELECT_ALL_VALUE) > -1) {
            setValue(
                props.fieldName,
                availableChoices
                    .map((e) => e[props.optionValue])
                    .filter((e) => e !== SELECT_ALL_VALUE)
            )
        }
    }
    const superOnChange = (event) => {
        onChange(event)
        if (props.onChange) {
            props.onChange(event, availableChoices)
        }
    }
    const {multipleChoice, alwaysOn, ...rest} = props;
    if(props.multipleChoice) {
        return <SelectArrayInput {...props} onChange={superOnChange}/>
    } else {
        // noinspection RequiredAttributes
        return <SelectInput {...rest} onChange={superOnChange}/>
    }
}

export default SelectInputWithChoiceContext;

SelectInputWithChoiceContext.propTypes = {
  multipleChoice: PropTypes.bool,
  alwaysOn: PropTypes.bool,
  onChange: PropTypes.func,
  fieldName: PropTypes.string,
}